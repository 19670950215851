// Feature Flips
export const BOM_V2 = 'BOM_V2';

// Helper Vars
export const ALL_FEATURE_FLIPS = [BOM_V2];

export const TYPE_RELEASE = 'Release';
export const TYPE_OPS = 'Ops';
export const TYPE_PERMISSION = 'Permission';
export const TYPE_EXPERIMENT = 'Experiment';
// https://martinfowler.com/articles/feature-toggles.html
export const TYPES = [
  'Release', // Most Common. Feature Development. Canary
  'Ops', // Control potentially performance damaging changes
  'Permission', // Long-Lived access to features such as premium, etc.
  'Experiment', // A/B Testing
];

export const initialFeatureFlips = () => {
  return ALL_FEATURE_FLIPS.reduce(
    (acc: Record<string, number>, featureFlipName) => {
      acc[featureFlipName] = 0;
      return acc;
    },
    {},
  );
};
