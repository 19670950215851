import Script from 'next/script';
import { ENV_NAME, GOOGLE_TAG_MANAGER_ID } from '../../../SPAConstants';
export const ExternalScripts = () => {
  return <>
      {ENV_NAME === 'production' && <Script id="script-smartlook">
          {`window.smartlook||(function(d) {
              var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
              var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
              c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
            })(document);
            smartlook('init', 'aedbd6e211c0c244ad882e1bcd854d0a0cd77b75', { region: 'us' });
            smartlook('record', { forms: true, numbers: true, emails: true, ips: true });
          `}
        </Script>}
      <Script
    // Hubspot
    id="hs-script-loader" src="//js.hs-scripts.com/20244782.js" strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="ExternalScripts.tsx" />
      <Script id="script-linkedin-ads-config" data-sentry-element="Script" data-sentry-source-file="ExternalScripts.tsx">
        {`
        _linkedin_partner_id = "3612356";
        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
      `}
      </Script>
      <Script id="script-linkedin-ads" data-sentry-element="Script" data-sentry-source-file="ExternalScripts.tsx">
        {`
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
        `}
      </Script>
      <noscript>
        <img height="1" width="1" style={{
        display: 'none'
      }} alt="" src="https://px.ads.linkedin.com/collect/?pid=3612356&fmt=gif" />
      </noscript>
      <Script id="script-google-tag-manager" data-sentry-element="Script" data-sentry-source-file="ExternalScripts.tsx">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
        `}
      </Script>
      <noscript>
        <iframe src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG_MANAGER_ID}`} height="0" width="0" style={{
        display: 'none',
        visibility: 'hidden'
      }} />
      </noscript>
      <Script
    // To best leverage Stripe’s advanced fraud functionality, include this script on every page, not just the checkout page.
    // https://docs.stripe.com/js/including
    id="script-stripe" strategy="afterInteractive" src="https://js.stripe.com/v3/" data-sentry-element="Script" data-sentry-source-file="ExternalScripts.tsx" />
    </>;
};