/* Use lowercase because Sails converts all header keys to lowercase */
export const X_TRACE_ID_HEADER = 'x-trace-id';
export const X_SOURCE_HEADER = 'x-source';
export const USER_AGENT_HEADER = 'user-agent';
export const QUOTEBEAM_HEADER = 'quotebeam';

type RequestLibrary = 'fetch' | 'axios';

type StandardHeaders = {
  [USER_AGENT_HEADER]?: string;
  [X_SOURCE_HEADER]: string;
  [X_TRACE_ID_HEADER]: string;
};

/**
 * Gets the User-Agent header for server-side requests
 */
const setUserAgentHeader = (userAgent?: string | null) => {
  if (isBrowser || !userAgent) {
    // Don't set user agent on client, because browsers browser block such requests
    return null;
  }

  return {
    [USER_AGENT_HEADER]: userAgent,
  };
};

/**
 * Gets the x-source header to identify request origin
 */
const setXSourceHeader = (library: RequestLibrary) => {
  const currentAppName = process.env.CURRENT_APP_NAME || 'unknown';
  const env = isServer ? 'server' : 'client';

  return {
    [X_SOURCE_HEADER]: `${QUOTEBEAM_HEADER}/${env}/${currentAppName}/${library}`,
  };
};

/**
 * Gets a unique trace ID header for request tracking
 */
const setXTraceIdHeader = () => {
  if (typeof crypto === 'undefined' || !crypto.randomUUID) {
    return {
      [X_TRACE_ID_HEADER]: `qb-${Math.random().toString(36).substring(2, 20)}`,
    };
  }

  return { [X_TRACE_ID_HEADER]: `qb-${crypto.randomUUID()}` };
};

type GetStandardHeadersParams = {
  library: RequestLibrary;
  userAgent?: string | null;
};
/**
 * Combines all standard headers for HTTP requests
 */
export const getStandardHeaders = ({
  library,
  userAgent,
}: GetStandardHeadersParams): StandardHeaders => {
  const headers = {
    ...setUserAgentHeader(userAgent),
    ...setXSourceHeader(library),
    ...setXTraceIdHeader(),
  };

  return headers;
};

export const isServer = typeof window === 'undefined';
export const isBrowser = !isServer;
