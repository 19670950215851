import { colors, PaletteOptions, PaperProps } from '@mui/material';
import { CssVarsThemeOptions, extendTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import {
  changeTheFontFamily,
  resetSearchInputStyle,
  addTransitionShorthand,
  addXXLBreakpoint,
  mergeCssVarsThemeOptions,
} from './base-theme-utils';

export const defaultPalette: PaletteOptions = {
  spaNavbar: {
    // These colors are just a fallback for SPA, they should be overridden by the tenantPalette inside spaTheme.tsx
    navbarColor: '#1100CC',
    secondaryNavbarMainColor: '#1100CC',
    secondaryNavbarSecondaryColor: '#0E00A3',
  },
  primaryShared: {
    main: '#1100CC',
    dark: '#0E00A3',
    light: '#4C5BDB',
    '50p': '#98AAEC',
    '30p': '#98AAEC',
    '12p': '#DCE3F9',
    '8p': '#EDF0FC',
    '4p': '#F5F7FF',
    contrastText: '#fff',
  },
  primary: {
    main: '#1100CC',
    dark: '#0E00A3',
    light: '#4C5BDB',
    '50p': '#98AAEC',
    '30p': '#C3CDF4',
    '12p': '#DCE3F9',
    '8p': '#EDF0FC',
    '4p': '#F5F7FF',
    contrastText: colors.common.white,
  },
  secondary: {
    main: '#7200CC',
    dark: '#5B00A3',
    light: '#9259EF',
    '50p': '#B08BFF',
    '30p': '#D5C1FF',
    '12p': '#E1D2FF',
    '8p': '#EBE4FF',
    '4p': '#F2EDFF',
    contrastText: colors.common.white,
  },
  tertiary: {
    main: '#696974',
    dark: '#161519',
    light: '#95959E',
    '50p': '#797986',
    '30p': '#AFAFB6',
    '12p': '#DFDFE2',
    '8p': '#EAEAEC',
    '4p': '#F5F5F5',
    contrastText: colors.common.white,
  },
  accent: {
    main: '#FFDA00',
    dark: '#F0BC00',
    light: '#FFF58C',
    '50p': '#FCE569',
    '30p': '#FDEB8E',
    '12p': '#FEF3BA',
    '8p': '#FEFAE3',
    '4p': '#FFFCED',
    contrastText: colors.common.white,
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    '50p': '#e99797',
    '30p': '#f1c0c0',
    '12p': '#f9e6e6',
    '8p': '#fbeeee',
    '4p': '#fdf6f6',
    contrastText: colors.common.white,
  },
  success: {
    main: '#1C8259',
    dark: '#156645',
    light: '#6CE0B1',
    '50p': '#8dc0ac',
    '30p': '#bad9cd',
    '12p': '#e3f0eb',
    '8p': '#ecf5f1',
    '4p': '#f5faf8',
    contrastText: colors.common.white,
  },
  warning: {
    main: '#FF9800',
    dark: '#ED6C02',
    light: '#FF9800',
    '50p': '#ffcb7f',
    '30p': '#ffe0b2',
    '12p': '#fff2e0',
    '8p': '#fff6ea',
    '4p': '#fffaf4',
    contrastText: colors.common.white,
  },
  info: {
    main: '#0288d1',
    dark: '#01579b',
    light: '#03a9f4',
    '50p': '#80c3e8',
    '30p': '#b3dbf1',
    '12p': '#e0f0f9',
    '8p': '#eaf5fb',
    '4p': '#f4fafd',
  },
  text: {
    primary: '#2E2C34',
    secondary: '#696974',
    disabled: '#72727E',
  },
  background: {
    default: '#F5F6FA',
    paper: '#FFFFFF',
  },
  common: {
    editMode: '#FEFAE3',
    backgroundColor: '#F4F6F9',
  },
};

const defaultFontFamily = [
  'Inter',
  'Helvetica Neue',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');

export const createBaseThemeOptions = (
  customPalette: Partial<PaletteOptions>,
  fontFamily = defaultFontFamily,
): CssVarsThemeOptions => {
  // TODO: figure a way around creating an empty theme here.
  const theme = extendTheme();

  return mergeCssVarsThemeOptions(
    changeTheFontFamily(fontFamily),
    resetSearchInputStyle(),
    addTransitionShorthand,
    addXXLBreakpoint(),
    {
      colorSchemes: {
        light: {
          palette: deepmerge(defaultPalette, customPalette),
        },
      },
      typography: {
        h1: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 48,
          lineHeight: '150%',
          letterSpacing: 0,
          color: 'var(--mui-palette-text-primary)',
        },
        h2: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 36,
          lineHeight: '150%',
          letterSpacing: 0,
          color: 'var(--mui-palette-text-primary)',
        },
        h3: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 26,
          lineHeight: '130%',
          letterSpacing: 0,
          color: 'var(--mui-palette-text-primary)',
        },
        h4: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 20,
          lineHeight: '130%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-primary)',
        },
        h5: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '130%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-primary)',
        },
        h6: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '130%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-primary)',
        },
        body1: undefined,
        body2: undefined,
        bodyLarge: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-secondary)',
        },
        bodyMedium: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-secondary)',
        },
        paragraph: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '150%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-primary)',
        },
        bodySmall: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-text-secondary)',
        },
        bodyLargePrimary: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-primary-main)',
        },
        bodyMediumPrimary: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-primary-main)',
        },
        bodySmallPrimary: {
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-primary-main)',
        },
        bodySmallError: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 12,
          lineHeight: '120%',
          letterSpacing: '0.15px',
          color: 'var(--mui-palette-error-main)',
        },
        button: {
          textTransform: 'none',
          fontSize: '14px',
        },
      },
      components: {
        MuiTypography: {
          defaultProps: {
            variant: 'bodyLarge',
            variantMapping: {
              paragraph: 'p',
              bodyLarge: 'p',
              bodyMedium: 'p',
              bodySmall: 'p',
              bodyLargePrimary: 'p',
              bodyMediumPrimary: 'p',
              bodySmallPrimary: 'p',
              bodySmallError: 'p',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            sizeSmall: {
              borderRadius: 6,
              height: 30,
            },
            root: {
              borderRadius: 8,
              height: 40,
              '&:hover, &:focus': {
                textDecoration: 'none',
                outline: 0,
              },
            },
            sizeLarge: {
              borderRadius: 10,
              height: 50,
            },
            textSizeSmall: {
              textDecoration: 'underline',
              '&:hover, &:focus': {
                textDecoration: 'underline',
              },
            },
            textWarning: {
              color: 'var(--mui-palette-warning-dark)',
            },
            containedWarning: {
              backgroundColor: 'var(--mui-palette-warning-dark)',
            },
            outlinedWarning: {
              color: 'var(--mui-palette-warning-dark)',
              borderColor: 'var(--mui-palette-warning-dark)',
            },
          },
          defaultProps: {
            disableElevation: true,
          },
        },
        MuiLink: {
          defaultProps: {
            underline: 'hover',
          },
        },
        MuiPaper: {
          defaultProps: {
            elevation: 0,
            variant: 'outlined',
          },
          styleOverrides: {
            root: ({ ownerState }: { ownerState: PaperProps }) => ({
              ...(ownerState.square === false && {
                borderRadius: 14,
              }),
            }),
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              minHeight: 50,
              padding: theme.spacing(1, 1.5),
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: 50,
            },
          },
        },
        MuiTableContainer: {
          styleOverrides: {
            root: { padding: theme.spacing(3, 2) },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              '&.MuiTableRow-hover:hover': {
                backgroundColor: 'var(--mui-palette-grey-50)',
              },
            },
          },
        },
        MuiTableCell: {
          defaultProps: {},
          styleOverrides: {
            head: {
              backgroundColor: '#FAFAFB',
              fontWeight: 600,
              fontSize: '12px',
              lineHeight: 1.2,
              letterSpacing: 1,
              textTransform: 'uppercase',
              color: 'var(--mui-palette-text-primary)',
              borderBottom: '0 none',
              '&:first-of-type': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px',
              },
              '&:last-of-type': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
              },
            },
            body: {
              borderColor: 'var(--mui-palette-divider)',
              color: 'var(--mui-palette-text-secondary)',
              [theme.breakpoints.down('md')]: {
                fontSize: '14px',
              },
            },
            sizeSmall: {
              padding: theme.spacing(1, 2),
              '&.MuiTableCell-body': {
                fontSize: 14,
              },
            },
            sizeMedium: {
              padding: theme.spacing(2, 1.5),
              [theme.breakpoints.down('md')]: {
                padding: theme.spacing(1.5, 0.5),
              },
            },
          },
        },
        MuiAlert: {
          defaultProps: {
            severity: 'error',
          },
          styleOverrides: {
            root: {
              width: '100%',
              marginBottom: theme.spacing(2),
            },
          },
        },
        MuiFormGroup: {
          styleOverrides: {
            root: {
              width: 'fit-content',
            },
          },
        },
        MuiFormControlLabel: {
          styleOverrides: {
            root: {
              width: 'fit-content',
            },
            label: {
              fontWeight: 500,
            },
          },
          defaultProps: {
            slotProps: {
              typography: {
                variant: 'bodyMedium',
              },
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              height: theme.spacing(5),
              backgroundColor: 'var(--mui-palette-primary-4p)',
              color: 'var(--mui-palette-text-secondary)',

              '&.MuiToggleButton-root.Mui-selected': {
                color: 'var(--mui-palette-primary-contrastText)',
                backgroundColor: 'var(--mui-palette-primary-main)',
                '&:hover': {
                  backgroundColor: 'var(--mui-palette-primary-dark)',
                },
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              '&::placeholder': {
                color: 'var(--mui-palette-tertiary-30p)',
              },
              '&:-webkit-autofill': {
                // https://github.com/mui/material-ui/issues/283
                WebkitBoxShadow: 'unset',
                WebkitTextFillColor: 'unset',
              },
            },
          },
        },
        MuiDialogContentText: {
          defaultProps: {
            variant: 'bodyMedium',
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              '&:before': { content: 'none' }, // remove default additional 1px border top
            },
          },
        },
      },
    },
  );
};
